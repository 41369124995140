import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, Modal, Grid, TextField, Autocomplete } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import api from '../network/ApiClient';
import { loadLocations } from '../locations/Locations';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 500,
    xs: '100%',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: {
    md: 4,
    xs: 2,
  },
};

const LocationSelector = ({ locations, selectedLocation, onLocationChange }) => (
  <Autocomplete
    options={locations.data}
    getOptionLabel={(loc) => loc.name}
    value={locations.data.find((loc) => loc.id === selectedLocation) || null}
    onChange={(event, newValue) => {
      onLocationChange(newValue?.id || '');
    }}
    renderInput={(params) => <TextField {...params} label="Location" variant="outlined" fullWidth />}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    freeSolo
  />
);

export default function CreateRouteForm({ onCreateCallback, modalShow, setModalShow, onSubmitSuccess }) {
  const [locations, setLocations] = useState({ data: [] });

  useEffect(() => {
    const fetchLocations = async () => {
      await loadLocations(setLocations);
    };
    fetchLocations();
  }, []);

  const handleClose = () => {
    setModalShow(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      legs: [{ location: '', access: '2' }],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Route name is required'),
      legs: Yup.array().of(
        Yup.object({
          location: Yup.string().required('Please select a location'),
          access: Yup.string().required('Access is required'),
        })
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const legsWithIds = values.legs.map((leg, index) => ({
          id: `${values.name}-${index}`,
          ...leg,
        }));

        const newRoute = {
          id: `${values.name}-${Date.now()}`,
          name: values.name,
          legs: legsWithIds,
        };

        await api.post(`${process.env.REACT_APP_API_URL}/route`, newRoute);
        onCreateCallback(newRoute);
        onSubmitSuccess();
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setSubmitting(false);
        handleClose();
      }
    },
  });

  const handleAddLegChange = (key, value) => {
    formik.setFieldValue('legs', [{ ...formik.values.legs[0], [key]: value }]);
  };

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="create-route-modal"
      aria-describedby="create-route-form"
    >
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <h4 className="card-title mb-0">Create Route</h4>

            <TextField
              fullWidth
              name="name"
              label="Route Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputLabelProps={{ shrink: true }}
            />

            <LocationSelector
              locations={locations}
              selectedLocation={formik.values.legs[0]?.location}
              onLocationChange={(value) => handleAddLegChange('location', value)}
            />
          </Stack>

          <Grid container sx={{ mt: 2 }} justifyContent="space-between">
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting} // Disable button while submitting
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
