import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit, Trash, ArrowLeftCircle } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import ScheduleForm from './ScheduleForm';
import api from '../network/ApiClient';

const ScheduleRoute = () => {
  const { routeId } = useParams();
  const navigate = useNavigate();
  const [routeName, setRouteName] = useState('');
  const [rides, setRides] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editedRide, setEditedRide] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRideId, setSelectedRideId] = useState(null);
  const [actionType, setActionType] = useState('');

  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [showEditedDepart, setShowEditedDepart] = useState(false);

  const [newSchedule, setNewSchedule] = useState({
    start: '',
    end: '',
    date: '',
    depart: '',
    vehicle: '',
    driver: '',
    nth: '',
    every: '',
  });

  // Loading state
  const [loading, setLoading] = useState(false);

  const handlePopupConfirm = async (isWholeSeries) => {
    try {
      setLoading(true); // Start loading
      if (actionType === 'save') {
        const url = isWholeSeries ? `/ride/${selectedRideId}/series` : `/ride/${selectedRideId}`;
        const updatedFields = Object.keys(editedRide).reduce((acc, key) => {
          if (editedRide[key] !== undefined && editedRide[key] !== null) {
            acc[key] = editedRide[key];
          }
          return acc;
        }, {});

        updatedFields.depart = formatDate(updatedFields.depart).replace('am', '').replace('pm', '');
        await api.put(url, {
          depart: updatedFields.depart,
          vehicle: updatedFields.vehicle,
          driver: updatedFields.driver,
        });
        setRides(rides.map((ride) => (ride.id === selectedRideId ? editedRide : ride)));
      } else if (actionType === 'delete') {
        const url = isWholeSeries ? `/ride/${selectedRideId}/series` : `/ride/${selectedRideId}`;
        await api.delete(url);
        setRides(rides.filter((ride) => ride.id !== selectedRideId));
      }
      fetchRides();
      handlePopupClose();
      setShowEditedDepart(false);

      setEditingRow(null);
    } catch (error) {
      console.error(`Error ${actionType === 'delete' ? 'deleting' : 'updating'} ride:`, error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleNewScheduleChange = (name, value) => {
    setNewSchedule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchRides = async () => {
    await fetchData();
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Start loading
      const [routeRes, driversRes, vehiclesRes, ridesRes] = await Promise.all([
        api.get(`route/${routeId}`),
        api.get('/driver'),
        api.get('/vehicle'),
        api.get(`/ride?route=${routeId}`),
      ]);

      if (routeRes.data) {
        setRouteName(routeRes.data[0]?.name || 'Unknown Route');
      } else {
        navigate('/routes');
        return;
      }

      setDrivers(driversRes.data || []);
      setVehicles(vehiclesRes.data || []);

      const formattedRides = ridesRes.data.map((ride) => {
        return {
          id: ride.id,
          start: ride.depart.split(' ')[0], // Ensure date is formatted correctly
          depart: ride.depart.split(' ')[1],
          vehicle: ride.vehicle,
          driver: ride.driver,
        };
      });
      setRides(formattedRides);
    } catch (error) {
      console.error('Error fetching data:', error);
      navigate('/routes');
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchData();
  }, [routeId]);
  const handleEditClick = (ride) => {
    setEditingRow(ride.id);
    setShowEditedDepart(false);
    setEditedRide({ ...ride });
  };

  const handleSaveClick = (id) => {
    setActionType('save');
    setSelectedRideId(id);
    setOpenPopup(true);
  };

  const handleDeleteClick = (id) => {
    setActionType('delete');
    setSelectedRideId(id);
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
    setSelectedRideId(null);
  };

  const handleInputChange = (field, value) => {
    setEditedRide((prev) => ({ ...prev, [field]: value }));
  };

  const getDriverName = (driverId) => {
    const driver = drivers.find((d) => d.id === driverId);
    return driver ? `${driver.firstname} ${driver.lastname}` : '';
  };

  const getVehicleName = (vehicleId) => {
    const vehicle = vehicles.find((v) => v.id === vehicleId);
    return vehicle ? vehicle.name : '';
  };

  // Format time to hh:mm am/pm
  const formatDate = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const hoursNum = parseInt(hours, 10);
    const ampm = hoursNum >= 12 ? 'pm' : 'am';
    const formattedHours = hoursNum % 12 || 12; // Convert to 12-hour format
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        <IconButton onClick={() => navigate('/routes')}>
          <ArrowLeftCircle />
        </IconButton>
        Schedule Route
      </Typography>
      <Typography variant="h4" gutterBottom>
        {routeName}
      </Typography>
      <ScheduleForm
        routeId={routeId}
        newSchedule={newSchedule}
        handleNewScheduleChange={handleNewScheduleChange}
        vehicles={vehicles}
        drivers={drivers}
        fetchRides={fetchRides}
        setNewSchedule={setNewSchedule}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Depart</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Driver</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rides.map((ride) => (
              <TableRow key={ride.id}>
                <TableCell>
                  {editingRow === ride.id ? (
                    <TextField
                      type="date"
                      value={editedRide.start}
                      onChange={(e) => handleInputChange('start', e.target.value)}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  ) : (
                    ride.start
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === ride.id ? (
                    <TextField
                      label="Departure Time"
                      type="time"
                      value={showEditedDepart ? editedRide.depart : ride.depart.slice(0, 5)}
                      onChange={(e) => {
                        handleInputChange('depart', e.target.value);
                        setShowEditedDepart(true);
                      }}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  ) : (
                    formatDate(ride.depart)
                  )}
                </TableCell>

                <TableCell>
                  {editingRow === ride.id ? (
                    <Select
                      value={editedRide.vehicle}
                      onChange={(e) => handleInputChange('vehicle', e.target.value)}
                      variant="outlined"
                      size="small"
                    >
                      {vehicles.map((vehicle) => (
                        <MenuItem key={vehicle.id} value={vehicle.id}>
                          {vehicle.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    getVehicleName(ride.vehicle)
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === ride.id ? (
                    <Select
                      value={editedRide.driver}
                      onChange={(e) => handleInputChange('driver', e.target.value)}
                      variant="outlined"
                      size="small"
                    >
                      {drivers.map((driver) => (
                        <MenuItem key={driver.id} value={driver.id}>
                          {`${driver.firstname} ${driver.lastname}`}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    getDriverName(ride.driver)
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === ride.id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveClick(ride.id)}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  ) : (
                    <>
                      <IconButton color="primary" onClick={() => handleEditClick(ride)}>
                        <Edit size={18} />
                      </IconButton>
                      <IconButton style={{ color: 'red' }} onClick={() => handleDeleteClick(ride.id)}>
                        <Trash size={18} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openPopup} onClose={handlePopupClose}>
        <DialogTitle>{actionType === 'delete' ? 'Confirm Delete' : 'Confirm Save'}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handlePopupConfirm(true)} color="primary">
            Whole Series
          </Button>
          <Button onClick={() => handlePopupConfirm(false)} color="primary">
            Item Only
          </Button>
          <Button onClick={handlePopupClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScheduleRoute;
