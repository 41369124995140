import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftCircle } from 'react-feather';
import tippy from 'tippy.js'; // For tooltips
import 'tippy.js/dist/tippy.css';
import { getUser } from '../helpers/DecodeJWT';
import api from '../network/ApiClient';

const CalendarView = () => {
  const [rides, setRides] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().toISOString().slice(0, 7));
  const { routeId } = useParams();
  const navigate = useNavigate();

  const fetchRides = async (month) => {
    try {
      const res = await api.get(`/ride?route=${routeId}&organizergroup=${getUser().organizergroup}&month=${month}`);
      if (res.data) {
        setRides(
          res.data.map((ride) => ({
            id: ride.id,
            title: ride.routename,
            start: ride.depart.split(' ')[0],
            allDay: true,
            drivername: ride.drivername, // Assuming this is part of the API response
            vehiclename: ride.vehiclename, // Assuming this is part of the API response
          }))
        );
      } else {
        navigate('/routes');
      }
    } catch (error) {
      console.error('Error fetching rides:', error);
    }
  };

  useEffect(() => {
    fetchRides(currentMonth);
  }, [currentMonth]);

  const handleDateChange = (date) => {
    const monthDate = new Date(date);
    monthDate.setMonth(monthDate.getMonth() + 1);
    const month = monthDate.toISOString().slice(0, 7);

    fetchRides(month);
  };

  // Function to show the popup with driver and vehicle info
  const handleEventDidMount = (info) => {
    const event = info.event.extendedProps; // Access the extended properties of the event
    tippy(info.el, {
      content: `<div>Driver: ${event.drivername} <br/> Vehicle: ${event.vehiclename}</div>`,
      allowHTML: true,
      theme: 'light',
    });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        gap: '10px',
        flexDirection: 'column',
      }}
    >
      <Link
        to={'/routes'}
        style={{
          width: '6rem',
          height: '2.5rem',
          padding: '10px',
          color: 'white',
          backgroundColor: 'black',
          borderRadius: '5px',
          textDecoration: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <ArrowLeftCircle /> Back
      </Link>
      <FullCalendar
        dayPopoverFormat={{ month: 'long', day: 'numeric' }}
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={rides}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        }}
        editable={false}
        datesSet={(dateInfo) => handleDateChange(dateInfo.start)}
        eventDidMount={handleEventDidMount} // Attach popup on event hover
      />
    </div>
  );
};

export default CalendarView;
