import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Stack, Button, Container, Typography, IconButton, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Edit, Trash } from 'react-feather';
import Iconify from '../components/iconify';
import CreateVehicleForm from './CreateVehicleForm';
import api from '../network/ApiClient';

const VehiclesPage = () => {
  const [vehicles, setVehicles] = useState([]);
  const [modalNewVehicle, setModalNewVehicleShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const loadVehicles = async () => {
    try {
      const response = await api.get('/vehicle');
      setVehicles(response.data);
    } catch (error) {
      console.error('Error loading vehicles:', error);
    }
  };

  useEffect(() => {
    loadVehicles();
  }, []);

  const handleEdit = (vehicle) => {
    setSelectedVehicle(vehicle);
    setEdit(true);
    setModalNewVehicleShow(true);
  };

  const handleDelete = async (vehicleId) => {
    const permission = window.confirm('Do you want to delete this vehicle?');
    if (!permission) return;

    try {
      await api.delete(`/vehicle/${vehicleId}`);
      loadVehicles();
    } catch (error) {
      console.error('Error deleting vehicle:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'number', headerName: 'Number', flex: 1 },
    { field: 'capacity', headerName: 'Capacity', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'costperkm', headerName: 'Cost per Km', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <Stack direction="row" spacing={2}>
          <IconButton color="primary" size="small" onClick={() => handleEdit(cellValues.row)}>
            <Edit size={15} />
          </IconButton>
          <IconButton color="error" size="small" onClick={() => handleDelete(cellValues.row.id)}>
            <Trash size={15} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Vehicles</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vehicles
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setEdit(false);
              setSelectedVehicle(null);
              setModalNewVehicleShow(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create Vehicle
          </Button>
        </Stack>

        <CreateVehicleForm
          onCreateCallback={() => {
            setModalNewVehicleShow(false);
            loadVehicles();
          }}
          modalShow={modalNewVehicle}
          setModalShow={setModalNewVehicleShow}
          edit={edit}
          vehicle={selectedVehicle}
        />

        <Box sx={{ width: '100%', height: 400 }}>
          <DataGrid rows={vehicles} columns={columns} pageSize={5} />
        </Box>
      </Container>
    </>
  );
};

export default VehiclesPage;
