import React, { useState } from 'react';
import { Select, MenuItem, TextField, Button, Grid, Box, Alert } from '@mui/material';
import { Plus } from 'react-feather';
import { toast } from 'react-toastify';
import { getUser } from '../helpers/DecodeJWT';
import api from '../network/ApiClient';

const ScheduleForm = ({
  routeId,
  setNewSchedule,
  newSchedule,
  handleNewScheduleChange,
  vehicles,
  drivers,
  fetchRides,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async () => {
    if (newSchedule.start === '') {
      setNewSchedule({ ...newSchedule, start: getTomorrowDate() });
    }
    if (
      newSchedule.start === '' ||
      newSchedule.end === '' ||
      newSchedule.every === '' ||
      newSchedule.nth === '' ||
      newSchedule.depart === '' ||
      newSchedule.vehicle === '' ||
      newSchedule.driver === ''
    ) {
      setErrorMessage('Please fill in all fields before submitting.');
      return;
    }

    const scheduleData = {
      start: newSchedule.start,
      end: newSchedule.end,
      every: Number(newSchedule.every),
      nth: Number(newSchedule.nth),
      depart: newSchedule.depart,
      vehicle: newSchedule.vehicle,
      driver: newSchedule.driver,
      route: Number(routeId),
      organizergroup: getUser().organizergroup,
    };

    try {
      const response = await api.post('/ride', scheduleData);
      if (response.data.failed > 0 && response.data.report) {
        const errorMsg = Object.values(response.data.report).join(' ');
        setErrorMessage(errorMsg);
      } else {
        fetchRides();
        setNewSchedule({ date: '', depart: '', vehicle: '', driver: '', nth: '', start: '', end: '' });
        setErrorMessage('');
        toast.success(`${response.data.added} Rides Added`);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());
    return tomorrow.toISOString().split('T')[0];
  };

  return (
    <Box sx={{ marginBottom: 2, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Start Date"
            type="date"
            value={newSchedule.start || getTomorrowDate()}
            onChange={(e) => handleNewScheduleChange('start', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: getTomorrowDate() }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="End Date"
            type="date"
            value={newSchedule.end}
            onChange={(e) => handleNewScheduleChange('end', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            value={newSchedule.every}
            onChange={(e) => handleNewScheduleChange('every', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>Every (Frequency)</em>
            </MenuItem>
            <MenuItem value={0}>Every day</MenuItem>
            <MenuItem value={1}>Monday</MenuItem>
            <MenuItem value={2}>Tuesday</MenuItem>
            <MenuItem value={3}>Wednesday</MenuItem>
            <MenuItem value={4}>Thursday</MenuItem>
            <MenuItem value={5}>Friday</MenuItem>
            <MenuItem value={6}>Saturday</MenuItem>
            <MenuItem value={7}>Sunday</MenuItem>
            <MenuItem value={8}>Every Weekday</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Select
            value={newSchedule.nth}
            onChange={(e) => handleNewScheduleChange('nth', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              <em>Nth Occurrence</em>
            </MenuItem>
            <MenuItem value={1}>1st</MenuItem>
            <MenuItem value={2}>2nd</MenuItem>
            <MenuItem value={3}>3rd</MenuItem>
            <MenuItem value={4}>4th</MenuItem>
            <MenuItem value={5}>5th</MenuItem>
            <MenuItem value={6}>6th</MenuItem>
            <MenuItem value={7}>7th</MenuItem>
            <MenuItem value={8}>8th</MenuItem>
            <MenuItem value={9}>9th</MenuItem>
            <MenuItem value={10}>10th</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Departure Time"
            type="time"
            value={newSchedule.depart}
            onChange={(e) => handleNewScheduleChange('depart', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Select
            value={newSchedule.vehicle}
            onChange={(e) => handleNewScheduleChange('vehicle', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Vehicle</em>
            </MenuItem>
            {vehicles?.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Select
            value={newSchedule.driver}
            onChange={(e) => handleNewScheduleChange('driver', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Driver</em>
            </MenuItem>
            {drivers?.map((driver) => (
              <MenuItem key={driver.id} value={driver.id}>
                {`${driver.firstname} ${driver.lastname}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleFormSubmit} startIcon={<Plus />}>
            Add Schedule
          </Button>
        </Grid>
      </Grid>

      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export default ScheduleForm;
