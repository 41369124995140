import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
  Modal,
} from '@mui/material';
import { ArrowLeftCircle, PlusCircle, Trash } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import DurationPicker from 'react-duration-picker';
import api from '../network/ApiClient';

const EditRoutes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [legs, setLegs] = useState([]);
  const [locations, setLocations] = useState({});
  const [newLeg, setNewLeg] = useState({
    location: '',
    eta: { hours: 0, minutes: 0, seconds: 0 },
    dwell: { hours: 0, minutes: 0, seconds: 0 },
    cost: '',
    embark: true,
    disembark: true,
  });
  const [showNewLegInputs, setShowNewLegInputs] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isEtaModalOpen, setEtaModalOpen] = useState(false);
  const [isDwellModalOpen, setDwellModalOpen] = useState(false);

  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const res = await api.get(`${process.env.REACT_APP_API_URL}route/${id}`);
        if (res.data && res.data[0]) {
          setLegs(res.data[0].legs.reverse() || []);
        } else {
          navigate('/routes');
        }
      } catch (error) {
        navigate('/routes');
      }
    };

    const getAllLocations = async () => {
      try {
        const res = await api.get(`${process.env.REACT_APP_API_URL}location`);
        const locationMap = res.data.reduce((map, location) => {
          map[location.id] = location.name;
          return map;
        }, {});
        setLocations(locationMap);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRoute();
    getAllLocations();
  }, [id, navigate]);

  const handleDelete = (legId) => {
    const consent = window.confirm('Do you want to delete this Leg?');
    if (!consent) return;
    setLegs(legs.filter((leg) => leg.id !== legId));
  };

  const formatDuration = (duration) => {
    const { hours, minutes, seconds } = duration;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const handleAddLeg = async () => {
    if (!newLeg.location || !newLeg.cost) {
      alert('Please fill all fields!');
      return;
    }

    setLoading(true);

    try {
      const legData = {
        location: Number(newLeg.location),
        eta: formatDuration(newLeg.eta),
        dwell: formatDuration(newLeg.dwell),
        cost: newLeg.cost,
        access: handleAccessCalculation(newLeg.embark, newLeg.disembark).toString(),
      };
      await api.post(`${process.env.REACT_APP_API_URL}route/${id}/addleg`, legData);
      setLegs((prevLegs) => [...prevLegs, legData]);
      setNewLeg({
        location: '',
        eta: { hours: 0, minutes: 0, seconds: 0 },
        dwell: { hours: 0, minutes: 0, seconds: 0 },
        cost: '',
        embark: false,
        disembark: false,
      });
      setShowNewLegInputs(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccessCalculation = (embark, disembark) => {
    let access = '0';
    if (embark && disembark) {
      access = '3';
    } else if (embark) {
      access = '2';
    } else if (disembark) {
      access = '1';
    }
    return access;
  };

  const handleDurationChange = (type, value) => {
    setNewLeg((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        <IconButton sx={{ color: 'black' }} onClick={() => navigate('/routes')}>
          <ArrowLeftCircle />
        </IconButton>{' '}
        Edit Route
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Leg</TableCell>
              <TableCell>Stop</TableCell>
              <TableCell>ETA</TableCell>
              <TableCell>Dwell</TableCell>
              <TableCell>Distance (km) (Calculated)</TableCell>
              <TableCell>Cost (N$)</TableCell>
              <TableCell>Embark</TableCell>
              <TableCell>Disembark</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {legs.map((leg, index) => (
              <TableRow key={leg.id}>
                <TableCell>{index === 0 ? 'Start' : `Stop ${index}`}</TableCell>
                <TableCell>{locations[leg.location] || 'Unknown Location'}</TableCell>
                <TableCell>{leg.eta}</TableCell>
                <TableCell>{leg.dwell}</TableCell>
                <TableCell>{leg.distance}</TableCell>
                <TableCell>{index === 0 ? '' : leg.cost ? `N$ ${leg.cost}` : ''}</TableCell>
                <TableCell>
                  <Checkbox checked={leg.access === '2' || leg.access === '3' || index === 0} disabled />
                </TableCell>
                <TableCell>
                  <Checkbox checked={leg.access === '1' || leg.access === '3' || index === legs.length - 1} disabled />
                </TableCell>
                <TableCell>
                  <IconButton sx={{ color: 'red' }} size="small" onClick={() => handleDelete(leg.id)}>
                    <Trash size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              {showNewLegInputs ? (
                <>
                  <TableCell>New</TableCell>

                  <TableCell>
                    <Select
                      value={newLeg.location}
                      onChange={(e) => setNewLeg({ ...newLeg, location: e.target.value })}
                      fullWidth
                      sx={{ width: '150px' }} // Control width
                    >
                      {Object.entries(locations).map(([id, name]) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>

                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => setEtaModalOpen(true)}>
                      {formatDuration(newLeg.eta)}
                    </Button>
                    <Modal open={isEtaModalOpen} onClose={() => setEtaModalOpen(false)}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                          gap: 2,
                        }}
                      >
                        <DurationPicker
                          onChange={(value) => handleDurationChange('eta', value)}
                          initialDuration={{ hours: 1, minutes: 2, seconds: 3 }}
                          maxHours={5}
                        />
                        <Button onClick={() => setEtaModalOpen(false)} variant="contained" sx={{ marginTop: '10px' }}>
                          Confirm
                        </Button>
                      </Box>
                    </Modal>
                  </TableCell>

                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => setDwellModalOpen(true)}>
                      {formatDuration(newLeg.dwell)}
                    </Button>
                    <Modal open={isDwellModalOpen} onClose={() => setDwellModalOpen(false)}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                          gap: 2,
                        }}
                      >
                        <DurationPicker
                          onChange={(value) => handleDurationChange('dwell', value)}
                          initialDuration={{ hours: 1, minutes: 2, seconds: 3 }}
                          maxHours={5}
                        />
                        <Button onClick={() => setDwellModalOpen(false)} variant="contained" sx={{ marginTop: '10px' }}>
                          Confirm
                        </Button>
                      </Box>
                    </Modal>
                  </TableCell>

                  <TableCell>
                    <Box>{''}</Box>
                  </TableCell>

                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={newLeg.cost}
                      onChange={(e) => setNewLeg({ ...newLeg, cost: e.target.value })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={newLeg.embark}
                      onChange={(e) => setNewLeg({ ...newLeg, embark: e.target.checked })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={newLeg.disembark}
                      onChange={(e) => setNewLeg({ ...newLeg, disembark: e.target.checked })}
                    />
                  </TableCell>

                  <TableCell>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginRight: '8px' }}
                        disabled={loading}
                        onClick={handleAddLeg}
                      >
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary" onClick={() => setShowNewLegInputs(false)}>
                        Cancel
                      </Button>
                    </Box>
                  </TableCell>
                </>
              ) : (
                <TableCell colSpan={9}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusCircle />}
                    onClick={() => setShowNewLegInputs(true)}
                  >
                    Add New Leg
                  </Button>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EditRoutes;
